import router from './router'
import storage from 'store'
import store from '@/store'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

import notification from 'ant-design-vue/es/notification'

const allowList = ['login']
const loginRoutePath = '/user/login'
const defaultRoutePath = '/'

// 路由拦截，未登录用户重定向到登录页面
router.beforeEach(async (to, from, next) => {
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))

  if (storage.get('ACCESS_TOKEN')) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
    } else {
      // check login user.roles is null
      if (store.getters.roles.permissionList.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then(res => {
            const roles = res && res.role
            // generate dynamic router
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.addRoutes(store.getters.addRouters)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              const redirect = decodeURIComponent(from.query.redirect || to.path)
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true })
              } else {
                // 跳转到目的路由
                next({ path: redirect })
              }
            })
          })
          .catch((error) => {
            const errormessage = error ? error.message: '请求用户信息失败，请稍后重试'
            notification.error({
              message: '错误',
              description: errormessage
              // description: '请求用户信息失败，请稍后重试'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            // store.dispatch('Logout').then(() => {
            //   next({ path: loginRoutePath, query: { redirect: to.fullPath} })
            // })
            setTimeout(() => {
              store.dispatch('Logout').then(() => {
                next({ path: loginRoutePath, query: { redirect: to.fullPath} })
              })
            }, 1000);//延迟1s执行以显示错误信息
          })
      } else {
        next()
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
    }
  }
})