import request from "@/utils/request";

const userApi = {
  GetUserInfo: "/user/info",
  GetUserRoles: "/auth-user/roles",
  GetSearchProductHistory: "/user/viewed-product",
  Login: "/auth/sign-in-admin",
  SignUp: "/auth/sign-up",
  IsLogin: "/auth/is-login",
  Logout: "/auth/logout",
  ChangePassword: "/auth/change-password",
  ChangeUserName: "/user/change-username",
  ResetUserPwd: "/user/reset-password",
  UpdateUser: "/user/update-user",
  UserInfo: "/user/user-info",

  GetUserOrgAndGroupByUserName: "/auth-user-group/user-org-group",

  //企业微信相关接口
  VerifyQYWXLogin: "/qr-auth/verify",
  BindBeforeLogin: "/qr-auth/bind-before-login",
  BindAfterLogin: "/qr-auth/bind-after-login",
  UnbindQYWX: "/qr-auth/unbind",
};

export function getUserInfo() {
  return request({
    url: userApi.GetUserInfo,
    method: "get",
  });
}

export function getUserRoles() {
  return request({
    url: userApi.GetUserRoles,
    method: "get",
  });
}

export function getSearchProductHistory() {
  return request({
    url: userApi.GetSearchProductHistory,
    method: "get",
  });
}

export function login(parameter) {
  return request({
    url: userApi.Login,
    method: "post",
    data: parameter,
  });
}

export function signUp(parameter) {
  return request({
    url: userApi.SignUp,
    method: "post",
    data: parameter,
  });
}

export function isLogin() {
  return request({
    url: userApi.IsLogin,
    method: "get",
  });
}

export function logout() {
  return request({
    url: userApi.Logout,
    method: "post",
  });
}

export function changePassword(parameter) {
  return request({
    url: userApi.ChangePassword,
    method: "post",
    data: parameter,
  });
}

export function changeUserName(parameter) {
  return request({
    url: userApi.ChangeUserName,
    method: "post",
    data: parameter,
  });
}

export function resetUserPwd(parameter) {
  return request({
    url: userApi.ResetUserPwd + `/${parameter.userId}`,
    method: "post",
  });
}
export function updateUser(parameter) {
  return request({
    url: userApi.UpdateUser,
    data: parameter,
    method: "post",
  });
}
export function userInfo(parameter) {
  return request({
    url: userApi.UserInfo,
    method: "get",
    params: parameter,
  });
}

export function verifyQYWXLogin(parameter) {
  return request({
    url: userApi.VerifyQYWXLogin,
    method: "post",
    data: parameter,
  });
}

export function bindBeforeLogin(parameter) {
  return request({
    url: userApi.BindBeforeLogin,
    method: "post",
    data: parameter,
  });
}

export function bindAfterLogin(parameter) {
  return request({
    url: userApi.BindAfterLogin,
    method: "post",
    data: parameter,
  });
}

export function unbindQYWX(parameter) {
  return request({
    url: userApi.UnbindQYWX,
    method: "post",
    data: parameter,
  });
}

export function getUserOrgAndGroupByUserName(parameter) {
  return request({
    url: userApi.GetUserOrgAndGroupByUserName,
    method: "get",
    params: parameter,
  });
}
