import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  timeout: 90000 // 请求超时时间
})

// 用于处理重复请求的参数
const CancelToken = axios.CancelToken
let lastRequest = {}
let lastRequestTime = null
// 重复请求拦截
function handleRequest({ config }) {
  // 提取四个参数用于区分相同的请求
  const { url, method, data, params} = config;
  const jData = JSON.stringify(data), jParams = JSON.stringify(params)

  // 重复请求侦测时间为300ms
  const repeat = lastRequest.url === url
    && lastRequest.method === method
    && lastRequest.data === jData
    && lastRequest.params === jParams
    && (new Date().getTime() - lastRequestTime < 300)
  if (repeat) {
    // 这里是重点，实例化CancelToken时，对参数 c 进行立即调用，即可立即取消当前请求
    config.cancelToken = new CancelToken(c => c(`重复的请求被主动拦截: ${url} + ${jData} + ${jParams}`))
  } else {
    // 如果请求不存在，将数据转为JSON字符串格式，后面比较好对比
    lastRequest = {
      url,
      data: jData,
      params: jParams,
      method,
    }
  }
  lastRequestTime = new Date().getTime()
}

// request interceptor
request.interceptors.request.use(config => {
  handleRequest({ config })
  return config
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get('ACCESS_TOKEN')
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
// request.interceptors.request.use(config => {
//   const token = storage.get(ACCESS_TOKEN)
//   // 如果 token 存在
//   // 让每个请求携带自定义 token 请根据实际情况自行修改
//   // if (token) {
//   //   config.headers['Access-Token'] = token
//   // }
//   return config
// }, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if(response.data && response.data.code === 1) {
    notification.error({
      message: '系统提示',
      description: response.data.message || '接口调用失败'
    })
    throw "err"
  }
  // 用户未登录时，跳转登录页面
  if (response.data && response.data.code === 1101) {
    storage.remove('ACCESS_TOKEN')
    window.location.href = '/';
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
