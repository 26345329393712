<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout-header class="header">
      <img
        class="logo"
        src="../common/images/logo.png"
        @click="popToRoot()"
        style="cursor: pointer"
      />
      <a-menu
        class="menu"
        theme="light"
        mode="horizontal"
        :default-selected-keys="this.defaultSelectedKeys"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item v-for="item in menus" :key="item.path">
          <router-link :to="{ name: item.name }">{{
            item.meta.title
          }}</router-link>
        </a-menu-item>
        <a-dropdown style="position: absolute; right: 50px">
          <span>
            <a-avatar
              size="small"
              :src="avater"
              style="vertical-align: middle; margin-right: 8px"
            />
            <span>{{ user.name }}</span>
          </span>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="handleToSettings">
              <a-icon type="setting" />个人设置
            </a-menu-item>
            <a-menu-item
              style="position: relative"
              key="group"
              @click="handleChangeGroup"
              @mouseover="groupShow = true"
              @mouseout="groupShow = false"
            >
              <div>
                <a-icon type="sync" style="margin-right: 8px" />
                所属企业
                <div class="group-tooltip" v-show="groupShow">
                  <div class="group-tooltip-top">
                    {{ curOrgAndGroup.orgName }}
                  </div>
                  <div class="group-tooltip-bottom">
                    {{ curOrgAndGroup.groupName }}
                  </div>
                </div>
              </div>
            </a-menu-item>
            <a-menu-item key="2" @click="handleLogout"
              ><a-icon type="logout" />退出登录</a-menu-item
            >
          </a-menu>
        </a-dropdown>
      </a-menu>
      <div id="orgChangeModel"></div>
    </a-layout-header>
    <a-layout-content style="background-color: white">
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script>
import ChangeGroupModal from "./user-center/user-manage/components/ChangeGroupModal.vue";
import { getUserOrgByUserId } from "@/api/auth";
import store from "@/store";
import { mapState } from "vuex";
import { Modal } from "ant-design-vue";

export default {
  name: "App",
  data() {
    return {
      groupShow: false,
      allOrgAndGroups: [],
      curOrgAndGroup: {},
      collapsed: false,
      defaultSelectedKeys: [this.$route.path.split("/").slice(0, 2).join("/")],
      user: {
        name: "",
      },
      ui: {
        authFileUpload: false,
        authFocusCompany: false,
      },
      menus: [],
    };
  },
  computed: {
    curGroupId() {
      return store.getters.userInfo.groupId;
    },
    curOrgId() {
      return store.getters.userInfo.orgId;
    },
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
    avater() {
      // console.log(this.$store.getters.avatarURL);
      return store.getters.avatarURL;
    },
  },
  async created() {
    this.loadOrgs();
    this.user.name = store.getters.nickname;
    const routes = this.mainMenu.find((item) => item.path === "/");
    this.menus =
      (routes &&
        routes.children.filter((path) => path.meta && !path.meta.hidden)) ||
      [];
  },
  methods: {
    loadOrgs() {
      this.allOrgAndGroups = [];
      getUserOrgByUserId({
        id: store.getters.userInfo.id,
        platform: "ADMIN",
      }).then((res) => {
        this.allOrgAndGroups = res.data;
        const _curOrg = this.allOrgAndGroups.find(
          (item) => item.authOrganization.id == this.curOrgId
        );
        const _curGroup = _curOrg.groups.find(
          (item) => item.id == this.curGroupId
        );
        this.curOrgAndGroup.orgId = _curOrg.authOrganization.id;
        this.curOrgAndGroup.orgName = _curOrg.authOrganization.name;
        this.curOrgAndGroup.groupId = _curGroup.id;
        this.curOrgAndGroup.groupName = _curGroup.name;
      });
    },
    popToRoot() {
      setTimeout(() => {
        this.$router.push({ path: "/" });
      }, 100);
    },
    handleLogout() {
      Modal.confirm({
        title: "退出登录",
        content: "确定退出登录吗？",
        onOk: () => {
          this.$store.dispatch("Logout").then(() => {
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 100);
          });
        },
        onCancel: () => {},
      });
    },
    handleToSettings() {
      this.$router.push({ name: "account" });
    },
    handleChangeGroup() {
      this.$dialog(
        ChangeGroupModal,
        {
          allOrgAndGroups: this.allOrgAndGroups,
          curOrgAndGroup: this.curOrgAndGroup,
        },
        {
          title: "切换企业",
          width: 500,
          centered: true,
          maskClosable: false,
          okText: "切换",
          bodyStyle: { maxHeight: "300px", overflow: "auto" },
          getContainer:() => document.getElementById('orgChangeModel')
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
#orgChangeModel {
  /deep/.ant-modal-body {
  padding: 0px 35px 20px 35px;
}
}
#components-layout-demo-top-side-2 {
  .header {
    background-color: white;
    .logo {
      width: 120px;
      height: 31px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px 28px 16px 0;
      float: left;
      background-color: white !important;
      padding: 2px;
    }
  }
}
/deep/ .ant-dropdown-menu-item {
  min-width: 100px;
  min-width: 100px;
  padding: 5px 0;
  text-align: center;
}
.group-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6px;
  position: absolute;
  left: -1px;
  top: -5px;
  border: 1px solid rgb(232, 232, 232);
  min-width: 120px;
  padding: 0 5px;
  height: 55px;
  background-color: rgb(255, 255, 255);
  transform: translate(-100%, 0);
  .group-tooltip-top {
    font-weight: 600;
  }
  .group-tooltip-bottom {
    font-size: 12px;
  }
}
</style>
