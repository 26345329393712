import VueRouter from 'vue-router'
import Vue from 'vue'
import { constantRouterMap } from '@/config/router.config'

// 同路径跳转冗余处理方案
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)


export default new VueRouter({
  mode: 'history',
  routes: constantRouterMap
})
