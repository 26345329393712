import Vue from 'vue'
import router from './router'
import store from './store'
import Index from './Index.vue'
import { VueAxios } from './utils/request'
import authentication from '@/utils/authentication'

import '@/core/lazy_use'
import './permission'
import './global.less' // global style


if (process.env.NODE_ENV === "production") {
  Vue.config.productionTip = true;
}

Vue.use(VueAxios)

Vue.use(authentication)

new Vue({
  router,
  store,
  render: h => h(Index)
}).$mount('#app')
