<template>
  <!-- <div class="body"> -->
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
  <!-- </div> -->
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style>
@import "~@css/ant-design-vue-plus.less";

.body {
  background-color: white;
}
</style>    