import request from "@/utils/request";

const authApi = {
  ChangeGroup: "/auth/change-group",

  GetAllRules: "/auth-role/apis",
  GetAllRoles: "/auth-role/list",
  AddRole: "/auth-role/add",
  DeleteRole: "/auth-role/delete",

  GetAuthInfo: "/auth-role/info",
  UpdateAuth: "/auth-role/update-auth",

  GetRoleInfo: "/auth-role/info",
  AddRule: "/auth-role/add-api",
  DeleteRule: "/auth-role/delete-api",

  GetAllUsers: "/auth-user/list",
  UpdateUserRole: "/auth-user/change-role",
  GetUserGroupByUserId: "/auth-user-group/list-group-by-user",
  GetUserOrgByUserId: "/auth-user-group/user-org-group-id",
  GetUserOrgGroupRole: "/auth-user/list-role-group-org",

  // 机构体系
  GetUserOrgList: "/auth-user-group/list-user-org",
  SaveUserOrg: "/auth-user-group/save-user-org",
  GetUserGroupDetailListByOrgId: "/auth-user-group/list-group-detail",
  SaveUserGroup: "/auth-user-group/save-user-group",
  GetUserGroupListByOrgId: "/auth-user-group/list-group-by-org",
  GetSharedUserGroup: "/auth-user-group/get-share-group",
  SavaUserGroupShared: "/auth-user-group/save-group-share",
  SaveUserGroupPermissionConfig: "/auth-user-group/save-permission-config",
  DeleteUserGroup: "/auth-user-group/del-user-group",
  DeleteUserOrg: "/auth-user-group/del-user-org",
};

export function changeGroup(parameter) {
  return request({
    url: authApi.ChangeGroup,
    method: "post",
    data: parameter,
  });
}

export function updateUserRole(parameter) {
  return request({
    url: authApi.UpdateUserRole,
    method: "post",
    data: parameter,
  });
}

export function getAllUsers() {
  return request({
    url: authApi.GetAllUsers,
    method: "get",
  });
}

export function deleteRule(parameter) {
  return request({
    url: authApi.DeleteRule,
    method: "post",
    data: parameter,
  });
}

export function getAuthInfo(parameter) {
  return request({
    url: authApi.GetAuthInfo,
    method: "get",
    params: parameter,
  });
}

export function updateAuth(parameter) {
  return request({
    url: authApi.UpdateAuth,
    method: "post",
    data: parameter,
  });
}

export function addRule(parameter) {
  return request({
    url: authApi.AddRule,
    method: "post",
    data: parameter,
  });
}

export function getRoleInfo(parameter) {
  return request({
    url: authApi.GetRoleInfo + `/${parameter.roleId}`,
    method: "get",
  });
}

export function deleteRole(parameter) {
  return request({
    url: authApi.DeleteRole + `/${parameter.roleId}`,
    method: "post",
  });
}

export function addRole(parameter) {
  return request({
    url: authApi.AddRole,
    method: "post",
    data: parameter,
  });
}

export function getAllRoles() {
  return request({
    url: authApi.GetAllRoles,
    method: "get",
  });
}

export function getAllRules() {
  return request({
    url: authApi.GetAllRules,
    method: "get",
  });
}

export function getUserGroupByUserId(parameter) {
  return request({
    url: authApi.GetUserGroupByUserId,
    method: "get",
    params: parameter,
  });
}

export function getUserOrgByUserId(parameter) {
  return request({
    url: authApi.GetUserOrgByUserId,
    method: "get",
    params: parameter,
  });
}

export function getUserOrgList() {
  return request({
    url: authApi.GetUserOrgList,
    method: "get",
  });
}

export function saveUserOrg(parameter) {
  return request({
    url: authApi.SaveUserOrg,
    method: "post",
    data: parameter,
  });
}

export function getUserGroupDetailListByOrgId(parameter) {
  return request({
    url: authApi.GetUserGroupDetailListByOrgId,
    method: "get",
    params: parameter,
  });
}

export function saveUserGroup(parameter) {
  return request({
    url: authApi.SaveUserGroup,
    method: "post",
    data: parameter,
  });
}

export function getUserGroupListByOrgId(parameter) {
  return request({
    url: authApi.GetUserGroupListByOrgId,
    method: "get",
    params: parameter,
  });
}

export function getSharedUserGroup(parameter) {
  return request({
    url: authApi.GetSharedUserGroup,
    method: "get",
    params: parameter,
  });
}

export function savaUserGroupShared(parameter) {
  return request({
    url: authApi.SavaUserGroupShared,
    method: "post",
    data: parameter,
  });
}

export function saveUserGroupPermissionConfig(parameter) {
  return request({
    url: authApi.SaveUserGroupPermissionConfig,
    method: "post",
    data: parameter,
  });
}

export function deleteUserGroup(parameter) {
  return request({
    url: `${authApi.DeleteUserGroup}/${parameter}`,
    method: "post",
  });
}

export function deleteUserOrg(parameter) {
  return request({
    url: `${authApi.DeleteUserOrg}/${parameter}`,
    method: "post",
  });
}

export function getUserOrgGroupRole(parameter) {
  return request({
    url: authApi.GetUserOrgGroupRole,
    method: "get",
    params: parameter,
  });
}
