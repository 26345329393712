import Vuex from 'vuex'
import Vue from 'vue'

import getters from './getters'

import user from './modules/user'
import permission from './modules/permission'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        permission,
    },
    state: {

    },
    mutations: {
  
    },
    actions: {
  
    },
    getters,
})
