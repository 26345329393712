import { login, logout, getUserRoles, getUserInfo } from '@/api/user'
import storage from 'store'

const user = {
    state: {
        name: '',
        token: '',
        avatarURL: '',
        roles: {
            permissionList: [],
            actionList: []
        },
        info: {}
    },
    mutations: {
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_AVATARURL: (state, avatarURL) => {
            state.avatarURL = avatarURL
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_INFO: (state, info) => {
            state.info = info
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(res => {
                    res.token = '4291d7da9005377ec9aec4a71ea837f'
                    storage.set('ACCESS_TOKEN', res.token, 7 * 24 * 60 * 60 * 1000)
                    commit('SET_TOKEN', res.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                Promise.all([getUserInfo(), getUserRoles()]).then((response) => {
                    let [info, roles] = response;
                    //合并用户多角色
                    let actions = []
                    let permissions = []
                    roles.data.forEach(item => {
                        actions = (item.action !== null && item.action !== '') ? actions.concat(item.action.split(',')) : actions
                        permissions = (item.route !== null && item.route !== '') ? permissions.concat(item.route.split(',')) : permissions
                    });
                    const actionList = Array.from(new Set(actions))
                    const permissionList = Array.from(new Set(permissions))
                    const result = { ...info.data, role: { permissionList, actionList } }
                    if (permissionList.length > 0) {
                        if (actionList.includes("login-admin")) {
                            commit('SET_ROLES', result.role)
                            commit('SET_INFO', result)
                        } else {
                            reject(new Error(`User has no login permission!`))
                        }
                    } else {
                        reject(new Error(`getInfo: Role's routing permissions must be a non-null array !`))
                    }
                    result.avatarURL = result.avatarURL || 'https://gw.alipayobjects.com/zos/rmsportal/jZUIxmJycoymBprLOUbT.png'
                    commit('SET_NAME', result.name)
                    commit('SET_AVATARURL', result.avatarURL)

                    resolve(result)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        Logout({ commit, state }) {
            return new Promise((resolve) => {
                logout(state.token).then(() => {
                    resolve()
                }).catch(() => {
                    resolve()
                }).finally(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', {permissionList:[],actionList:[]})
                    storage.remove('ACCESS_TOKEN')
                })
            })
        }
    }
}

export default user