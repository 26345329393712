import App from "@pages/App.vue";
import UserLayout from "@/layout/UserLayout";
import store from "../store";

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}
function redirectRule(childRoute) {
  const redirectRoute = []
  childRoute.forEach(item => {
    if (store.getters.roles.permissionList.includes(item.permission)) {
      redirectRoute.push(item.name)
    }
  })
  return redirectRoute
}

export const asyncRouterMap = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
    },
    redirect: () => {
      const childRoute = [
        {
          name: 'list',
          permission: "list"
        },
        {
          name: "fileManage",
          permission: "file-manage"
        },
        {
          name: "userCenter",
          permission: "user-center"
        },
        {
          name: "system",
          permission: "system-admin"
        }
      ]
      const redirectRoute = redirectRule(childRoute)
      redirectRoute.push('account')
      return {
        name: redirectRoute[0]
      }
    },
    component: App,
    children: [
      // list
      {
        path: "/list",
        name: "list",
        meta: {
          title: "查询列表",
          permission: ["list"],
        },
        component: () => import("@/pages/list/Main.vue"),
        redirect: () => {
          const childRoute = [
            {
              name: "companyList",
              permission: "company-list"
            },
            {
              name: "productList",
              permission: "product-list"
            },
            {
              name: "organizationList",
              permission: "organization-list"
            },
            {
              name: "fundList",
              permission: "fund-list"
            },
            {
              name: "lpList",
              permission: "admin-lp-list"
            },
            {
              name: "voteMemberList",
              permission: "vote-member-list"
            }
          ]
          const redirectRoute = redirectRule(childRoute)
          redirectRoute.push('404')
          return {
            name: redirectRoute[0]
          }
        },
        children: [
          {
            path: "/list/company-list",
            name: "companyList",
            meta: {
              title: "公司列表",
              permission: ["company-list"],
            },
            component: () => import("@pages/list/CompanyList.vue"),
          },
          {
            path: "/list/product-list",
            name: "productList",
            meta: {
              title: "项目列表",
              permission: ["product-list"],
            },
            component: () => import("@pages/list/ProductList.vue"),
          },
          {
            path: "/list/organization-list",
            name: "organizationList",
            meta: {
              title: "机构列表",
              permission: ["organization-list"],
            },
            component: () => import("@pages/list/OrganizationList.vue"),
          },
          // {
          //   path: "/list/fund-list",
          //   name: "fundList",
          //   meta: {
          //     title: "基金列表",
          //     permission: ["fund-list"],
          //   },
          //   component: () => import("@pages/list/FundList.vue"),
          // },
          // {
          //   path: "/list/lp-list",
          //   name: "lpList",
          //   meta: {
          //     title: "出资人列表",
          //     permission: ["admin-lp-list"],
          //   },
          //   component: () => import("@pages/list/LpList.vue"),
          // },
          // {
          //   path: "/list/vote-member-list",
          //   name: "voteMemberList",
          //   meta: {
          //     title: "投委列表",
          //     permission: ["vote-member-list"],
          //   },
          //   component: () => import("@pages/list/VoteMemberList.vue"),
          // },
        ],
      },

      // 公司表单
      {
        path: "company/:companyId",
        name: "companyDetail",
        meta: {
          title: "公司管理",
          hidden: true,
          permission: ["company-list"],
        },
        component: () => import("@pages/company/detail/index.vue"),
        redirect: "/company/:companyId/info",
        children: [
          {
            path: "info",
            name: "companyBaseInfo",
            meta: {
              title: "公司详情",
              permission: ["company-list"],
            },
            component: () =>
              import("@pages/company/detail/basic/company-base-info"),
          },
          {
            path: "product-manage",
            name: "productManage",
            meta: {
              title: "项目管理",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/product-manage"),
          },
          {
            path: "team-manage",
            name: "teamManage",
            meta: {
              title: "团队管理",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/team-manage"),
          },
          {
            path: "finance-manage",
            name: "financeManage",
            meta: {
              title: "融资管理",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/finance-manage"),
          },
          {
            path: "recruit-manage",
            name: "recruitManage",
            meta: {
              title: "招聘信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/recruit-manage"),
          },
          {
            path: "patent-manage",
            name: "patentManage",
            meta: {
              title: "专利信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/patent-manage"),
          },
          {
            path: "govern-reward-manage",
            name: "governRewardManage",
            meta: {
              title: "政府奖励",
              permission: ["company-list"],
            },
            component: () =>
              import("@pages/company/detail/govern-reward-manage"),
          },
          {
            path: "license-manage",
            name: "licenseManage",
            meta: {
              title: "专项证照",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/license-manage"),
          },
          {
            path: "contest-manage",
            name: "contestManage",
            meta: {
              title: "比赛信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/contest-manage"),
          },
          {
            path: "exhibition-manage",
            name: "exhibitionManage",
            meta: {
              title: "展会信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/exhibition-manage"),
          },
          {
            path: "roadshow-manage",
            name: "roadshowManage",
            meta: {
              title: "路演信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/roadshow-manage"),
          },
          {
            path: "top-list-manage",
            name: "topListManage",
            meta: {
              title: "榜单信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/top-list-manage"),
          },
          {
            path: "venturecamp-manage",
            name: "venturecampManage",
            meta: {
              title: "创业营信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/venturecamp-manage"),
          },
          {
            path: "news-manage",
            name: "newsManage",
            meta: {
              title: "公司新闻",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/news-manage"),
          },
          {
            path: "shareholder-manage",
            name: "shareholderManage",
            meta: {
              title: "股东信息",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/shareholder-manage"),
          },
          {
            path: "standard-manage",
            name: "standardManage",
            meta: {
              title: "标准管理",
              permission: ["company-list"],
            },
            component: () => import("@pages/company/detail/standard-manage"),
          },
        ],
      },

      // 项目表单
      {
        path: "/product/:productId",
        name: "productDetail",
        meta: {
          title: "项目管理",
          hidden: true,
          permission: ["product-list"],
        },
        component: () => import("@pages/product/detail/index.vue"),
        children: [
          {
            path: "info",
            name: "productBaseInfo",
            meta: {
              title: "项目详情",
              permission: ["product-list"],
            },
            component: () =>
              import("@pages/product/detail/basic/product-base-info.vue"),
          },
          {
            path: "news-manage",
            name: "productNewsManage",
            meta: {
              title: "项目新闻",
              permission: ["product-list"],
            },
            component: () => import("@pages/product/detail/news-manage"),
          },
        ],
      },

      {
        path: "organization/:organizationId",
        name: "organizationDetail",
        meta: {
          title: "机构管理",
          hidden: true,
          permission: ["organization-list"],
        },
        component: () => import("@pages/organization/index.vue"),
        redirect: "/organization/:organizationId/top-list-manage",
        children: [
          {
            path: "/organization/:organizationId/top-list-manage",
            name: "organizationTopList",
            meta: {
              title: "机构榜单分数",
              permission: ["organization-list"],
            },
            component: () =>
              import("@pages/organization/top-list-manage/index"),
          },
        ],
      },

      // {
      //   path: "fund/detail",
      //   name: "fundDetail",
      //   meta: {
      //     title: "基金管理",
      //     hidden: true,
      //     permission: ["fund-list"]
      //   },
      //   component: () => import("@pages/fund/index.vue"),
      //   redirect: "/fund/detail/basic-info",
      //   children: [
      //     {
      //       path: "/fund/detail/basic-info",
      //       name: "fundBaseInfo",
      //       meta: {
      //         title: "基金详情",
      //         permission: ["fund-list"]
      //       },
      //       component: () => import("@pages/fund/detail/basic-info")
      //     },
      //     {
      //       path: "/fund/detail/attribute",
      //       name: "fundAttribute",
      //       meta: {
      //         title: "基金属性",
      //         permission: ["fund-list"]
      //       },
      //       component: () => import("@pages/fund/detail/attribute")
      //     },
      //   ]
      // },

      // 我的关注
      // {
      //   path: 'focus-company',
      //   name: 'focusCompanyList',
      //   meta: {
      //     title: '我的关注',
      //     permission: ['followed-company']
      //   },
      //   component: () => import('@pages/focus-company'),
      //   redirect: '/focus-company/manage',
      //   children: [{
      //     path: 'manage',
      //     name: 'focusCompanyManage',
      //     meta: {
      //       title: '我的关注',
      //       permission: ['followed-company']
      //     },
      //     component: () => import('@pages/focus-company/focus-company-manage')
      //   }]
      // },

      // 个人设置
      {
        path: "/account",
        name: "account",
        meta: {
          title: "个人设置",
          hidden: true,
          // permission: ["auth"],
        },
        redirect: "/account/settings/Security",
        component: () => import("@/pages/account/index"),
        children: [
          {
            path: "/account/settings/Security",
            name: "security",
            meta: {
              title: "安全设置",
              hidden: true,
              // permission: ["auth"],
            },
            component: () => import("@/pages/account/settings/Security"),
          },
        ],
      },
      //评价体系
      {
        path: "/evaluation-system",
        name: "evaluation-system",
        meta: {
          title: "评价体系",
          permission: ["evaluation-system"],
        },
        component:() => import("@/pages/evaluation-system/index.vue"),
        redirect: () => {
          const childRoute = [
            {
              name: 'teamGrade',
              permission: "team-evaluation"
            },
          ]
          const redirectRoute = redirectRule(childRoute)
          redirectRoute.push('404')
          return {
            name: redirectRoute[0]
          }
        },
        children: [
          {
            path: "/evaluation-system/team-grade",
            name: "teamGrade",
            meta: {
              title: "团队评分体系",
              permission: ["team-evaluation"],
            },
            component: () => import("@/pages/evaluation-system/team-grade/List"),
          }
        ]
      },
      {
        path:'/evaluation-system/team-grade/unit-manage',
        name:'unitManage',
        meta: {
          title:'单位管理',
          permission: ["team-evaluation-unit-manage"],
          hidden:true
        },
        component: () => import("@/pages/evaluation-system/team-grade/unit-manage/index.vue"),
        redirect: () => {
          const childRoute = [
            {
              name: 'unitAbbreviation',
              permission: "team-evaluation-unit-abbreviation"
            },
            {
              name: 'unitTopListScore',
              permission: "team-evaluation-unit-top-list-score"
            }
          ]
          const redirectRoute = redirectRule(childRoute)
          redirectRoute.push('404')
          return {
            name: redirectRoute[0]
          }
        },
        children: [
          {
            path:'abbreviation',
            name: 'unitAbbreviation',
            meta: {
              title: '单位简称',
              permission: ["team-evaluation-unit-abbreviation"],
            },
            component: () => import("@/pages/evaluation-system/team-grade/unit-manage/abbreviation/index.vue"),
          },
          {
            path:'top-list-score',
            name: 'unitTopListScore',
            meta: {
              title: '单位榜单分数',
              permission: ["team-evaluation-unit-top-list-score"],
            },
            component: () => import("@/pages/evaluation-system/team-grade/unit-manage/top-list-score/index.vue"),
          },
        ]
      },
      // 文件管理
      {
        path: "/file-manage",
        name: "fileManage",
        meta: {
          title: "文件管理",
          permission: ["file-manage"],
        },
        component: () => import("@/pages/file-manage/index.vue"),
        redirect: "/file-manage/file-upload",
        children: [
          {
            path: "/file-manage/file-upload",
            name: "fileUpload",
            meta: {
              title: "文件上传",
              permission: ["file-manage"],
            },
            component: () => import("@/pages/file-manage/file-upload/Index.vue"),
          },
        ],
      },

      // 用户中心
      {
        path: "/user-center",
        name: "userCenter",
        meta: {
          title: "用户中心",
          permission: ["user-center"],
        },
        redirect: () => {
          const childRoute = [
            {
              name: 'roleManage',
              permission: "role-manage"
            },
            {
              name: "userManage",
              permission: "user-manage"
            }
          ]
          const redirectRoute = redirectRule(childRoute)
          redirectRoute.push('404')
          return {
            name: redirectRoute[0]
          }
        },
        component: () => import("@/pages/user-center/index.vue"),
        children: [
          {
            path: "role-manage/list",
            name: "roleManage",
            meta: {
              title: "角色管理",
              permission: ["role-manage"],
            },
            component: () =>
              import("@/pages/user-center/role-manage/index.vue"),
          },
          {
            path: "user-manage/list",
            name: "userManage",
            meta: {
              title: "用户管理",
              permission: ["user-manage"],
            },
            component: () =>
              import("@/pages/user-center/user-manage/index.vue"),
          },
          {
            path: "org-manage/list",
            name: "orgManage",
            meta: {
              title: "机构管理",
              permission: ["user-org-manage"],
            },
            component: () =>
              import("@/pages/user-center/org-manage/index.vue"),
          },
          {
            path: "org-manage/list/options",
            name: "orgOptions",
            meta: {
              title: "机构配置",
              permission: ["user-org-manage"],
            },
            component: () =>
              import("@/pages/user-center/org-manage/org-options.vue"),
          },
        ],
      },
      // 系统管理
      {
        path: "/system",
        name: "system",
        meta: {
          title: "系统管理",
          permission: ["system-admin"], // 待替换
        },
        redirect: () => {
          const childRoute = [
            {
              name: 'tagManage',
              permission: "tag"
            },
            {
              name: "updateInstructionsManage",
              permission: "update-note"
            },
            {
              name: "useInstructionsManage",
              permission: "use-note"
            },
            {
              name:"feedback",
              permission: "feedback"
            }
          ]
          const redirectRoute = redirectRule(childRoute)
          redirectRoute.push('404')
          return {
            name: redirectRoute[0]
          }
        },
        component: () => import("@/pages/system/Index.vue"),
        children: [
          {
            path: "/system/tag",
            name: 'tagManage',
            meta: {
              title: "标签管理",
              permission: ["tag"],
            },
            component: () => import('@/pages/system/tag/Index.vue'),
            redirect: '/system/tag/list',
            children: [
              {
                path: "list",
                name: "tagList",
                meta: {
                  title: "标签列表",
                  permission: ["tag"],
                  breadcrumb: '全部标签列表',
                },
                component: () => import("@/pages/system/tag/list/Index.vue"),
              },
              {
                path: "list/project",
                name: "tagProject",
                meta: {
                  title: "标签项目",
                  permission: ["tag"],
                  breadcrumb: '全部标签列表/标签详情',
                },
                component: () => import("@/pages/system/tag/project/Index.vue"),
              },
            ]
          },
          {
            path: "/system/tag/condition",
            name: "conditionManage",
            meta: {
              title: "条件管理",
              permission: ["tag"],
              breadcrumb: '全部标签列表/标签详情/当前筛选条件',
            },
            component: () => import("@/pages/system/tag/condition/Index.vue"),
          },
          {
            path: "/system/tag/sub-condition",
            name: "subConditionManage",
            meta: {
              title: "子条件管理",
              permission: ["tag-sub-condition"],
              breadcrumb: '全部标签列表/标签详情/当前筛选条件/编辑条件',
            },
            component: () => import("@/pages/system/tag/condition/SubCondition.vue"),
          },
          {
            path: "/system/update/list",
            name: "updateInstructionsManage",
            meta: {
              title: "更新说明管理",
              permission: ["update-note"],
              breadcrumb: '全部更新说明列表',
            },
            component: () => import("@/pages/system/update/List.vue"),
          },
          {
            path: "/system/use/list",
            name: "useInstructionsManage",
            meta: {
              title: "使用说明管理",
              permission: ["use-note"],
              breadcrumb: '全部使用说明模块',
            },
            component: () => import("@/pages/system/use/Index.vue"),
          },
          {
            path: "/system/feedback",
            name: "feedback",
            meta: {
              title: "用户反馈管理",
              breadcrumb: '用户反馈管理',
              permission: ["feedback"],
            },
            component: () => import("@/pages/system/feedback/Index.vue"),
            redirect: '/system/feedback/list',
            children: [
              {
                path: "list",
                name: "feedbackList",
                meta: {
                  title: "用户反馈列表",
                  breadcrumb: '用户反馈列表',
                  permission: ["feedback"],
                },
                component: () => import("@/pages/system/feedback/List.vue"),
              },
              {
                path: "detail",
                name: "feedbackDetail",
                meta: {
                  title: "用户反馈详情",
                  breadcrumb: '',
                  permission: ["feedback"],
                },
                component: () => import("@/pages/system/feedback/Detail.vue"),
              }
            ]
          },
          {
            path: "/system/liboratory",
            name: "liboratory",
            meta: {
              title: "起源实验室管理",
              breadcrumb: '起源实验室管理',
              permission: ["laboratory-config"],
            },
            component:RouteView,
            children:[
              {
                path: "list",
                name: "liboratoryList",
                meta: {
                  title: "起源实验室模块列表",
                  breadcrumb: '起源实验室模块列表',
                  permission: ["laboratory-config"],
                },
                component: () => import("@/pages/system/liboratory/List.vue"),
              },
              {
                path: "chat-links-list",
                name: "chatLinksList",
                meta: {
                  title: "ai 对话平台链接",
                  breadcrumb: '',
                  permission: ["chat-robot-config"],
                },
                component: () => import("@/pages/system/liboratory/chat-config/List.vue"),
              },
            ]
          }
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export var constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/pages/user/Login"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/pages/exception/404"),
  },
];
