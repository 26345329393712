<template>
  <div>
    <div v-for="(item, index) in allOrgAndGroups" :key="index" class="company">
      <div class="company-name">{{ item.authOrganization.name }}</div>
      <div class="company-group">
        <div
          v-for="(groupItem, groupIndex) in item.groups"
          :key="groupIndex"
          @click="handleChangeGroup(groupItem.authOrgId, groupItem.id)"
          style="position: relative"
          :class="groupItem.id == selGroupId ? 'select-group' : ''"
        >
          <span>{{ groupItem.name }}</span>
          <span style="position: absolute; right: 25px; font-size: 20px">
            <a-icon
              type="check-circle"
              style="color: #5584b1"
              v-show="groupItem.id == selGroupId"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeGroup } from "@/api/auth";
export default {
  name: "ChangeGroupModal",
  props: {
    allOrgAndGroups: {
      type: Array,
      default: () => [],
    },
    curOrgAndGroup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selOrgId: null,
      selGroupId: null,
    };
  },
  created() {
    this.selGroupId = this.curOrgAndGroup.groupId;
    localStorage.setItem("GROUP_ID", this.curOrgAndGroup.groupId);
    window.onstorage = (e) => {
      if (e.key == "GROUP_ID") {
        if (e.newValue != this.curOrgAndGroup.groupId) {
          sessionStorage.clear();
          location.reload();
        }
      }
    };
  },
  methods: {
    handleChangeGroup(orgId, groupId) {
      this.selGroupId = groupId;
      this.selOrgId = orgId;
    },
    onOK() {
      changeGroup({ groupId: this.selGroupId, orgId: this.selOrgId }).then(
        (res) => {
          localStorage.setItem("GROUP_ID", res.data.groupId);
          sessionStorage.clear();
          location.reload();
        }
      );
    },
  },
};
</script>

<style scoped>
.company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}
.company-name {
  font-weight: 600;
  color: rgb(26, 26, 26);
}
.company-group div {
  width: 430px;
  height: 55px;
  border-radius: 8px;
  border: 1px solid #779cbf;
  text-align: center;
  line-height: 55px;
  margin-top: 6px;
}
.company-group div:hover {
  cursor: pointer;
  background-color: #d2e2ee;
}
.select-group {
  background-color: #d2e2ee;
  border-color: #d2e2ee !important;
  color: rgb(50, 50, 50);
}
</style>
